<template>
  <div>

  </div>
</template>

<script>
import { getOffline } from "@api/public";
export default {
  data() {
    return {
      id: ''
    }
  },
  created() {
    this.id = this.$route.params.id;
    this.urlSkip();
  },
  methods: {
    urlSkip() {
      getOffline(this.id).then(res => {
        if(res.msg == 1) {
          this.$router.replace(`/lesson/offline/${this.id}`)
        } else {
          this.$router.replace(`/lesson/onlineDetail/${this.id}`)
        }
      })
    }
  }
}
</script>